import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Contact.css";
import MailingSend1 from "./ContactUsForm";
import "bootstrap/dist/css/bootstrap.css";
import FadeInSection from "./FadeInSection";

function Contact() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth", block: "end" });
    }
  }, [location]);
  return (
    <FadeInSection>
      <div> <h1 className="title">KONTAKTA OSS</h1>
      <div id="consultation" className=" consultation flex ">
        
        <MailingSend1 lg={3} class="text-lg-center pb-5 pt-5 " />
        </div>
      </div>
    </FadeInSection>
  );
}

export default Contact;
