import Form from "react-bootstrap/Form";
import "./Footer.css";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Contact.css";
import FadeInSection from "./FadeInSection";

//heroku

const MailingSend1 = () => {
  const [status, setStatus] = useState("Skicka");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Vänta...");
    const { name, email, phone, size, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      phone: phone.value,
      size: size.value,
      message: message.value,
    };
    let response = await fetch(
      "https://mailnode-svrent.herokuapp.com/contact",
      // "http://localhost:5000/contact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      }
    );
    function resetData() {
      e.target.reset();
    }
    setStatus("Skicka");
    let result = await response.json();
    alert(result.status);
    setTimeout(resetData, 3000);
  };

  return (
    <FadeInSection>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          className="form_control1 "
          controlId="exampleForm.ControlInput1"
          id="contactus"
        >
          <Form.Control
            className="rounded_text mb-3 "
            type="text"
            id="name"
            required
            placeholder="Namn och efternamn*"
          />

          <Form.Control
            className="rounded_text mb-3 "
            type="email"
            id="email"
            required
            placeholder="E-post: name@example.com *"
          />

          <Form.Control
            className="rounded_text mb-3 "
            type="tel"
            id="phone"
            placeholder="+46123456778*"
            required
          />
          <Form.Control
            className="rounded_text mb-3 "
            type="number"
            id="size"
            placeholder="m2"
          />
        </Form.Group>
        <Form.Group className="mb-2 form_control1">
          <Form.Control
            className="rounded_text "
            as="textarea"
            type="text"
            rows={6}
            cols={10}
            id="message"
            name="message"
            required
            placeholder="Meddelande*"
          />
          <div className="text-left">
            <Button
              variant="secondary"
              type="submit"
              id="submit"
              className="rounded mt-3"
            >
              {status}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </FadeInSection>
  );
};

export default MailingSend1;
