import React  from "react";
import "./Logo.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";



function  HeroSection () {
  
   
    return (
      <Link className="logo_link" to="/" >
      <div className="logo" id="logo"></div>
      </Link>
    );
  }

  export default HeroSection;
