import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import './HomeImages.css';
import 'bootstrap/dist/css/bootstrap.css';
 import FadeInSection from './FadeInSection';

function HomeImages() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <FadeInSection>
    <div id="homeImages" className="homeImages-container flex ">
      
      <h3 className="title text-center ">VI ÄLSKAR DET VI GÖR:<br/> GER RENT HEM OCH GÖR DITT LIV ENKELARE</h3>
      
      <div className="row">
    <div className="col-sm img-cont">
    <img className="home-images" src="../images/hi-1.jpg"  path="/about" alt=""/>
    <p  className="text-center">The professional cleaning</p>
    </div>
    <div className="col-sm img-cont">
    <img className="home-images" src="../images/hi-2.jpg"  path="/about" alt=""/>
    <p className="text-center">Join our team</p>
    </div>
    <div className="col-sm img-cont">
    <img className="home-images" src="../images/hi-3.jpg"  path="/about" alt=""/>
    <p className="text-center">Hours cleaning schedule</p>
    </div>
  </div>

    </div>
         
    </FadeInSection> 
        );
      }
     
       
 
  
export default HomeImages;