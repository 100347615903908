import React from 'react';
import '../App.css';
import './WhyUs.css'
import FadeInSection from "./FadeInSection";
import 'bootstrap/dist/css/bootstrap.css';


export default function MassaheHome() {
  return (
         <FadeInSection>
    <div className="massage-home" id="massage-home">
       <h1 className="title why">VARFÖR VÄLJA OSS</h1>

       <div class="container-whyus d-flex justify-content-left">
  <div class="row ">
    <div class="col-12 pic-1  picsall ">
   <span className="pics pics1"></span>
   <span className="content-pics text-left ">
   <p className="middle  ">Vi är proffs</p></span> 
   </div>
   <div class="col-12 pic-1  picsall">
   <span className="pics pics2"></span>
   <span className="content-pics text-left ">
   <p className="middle ">100 % nöjda kunder</p></span> 
   </div>
   <div class="col-12 pic-1  picsall">
   <span className="pics pics3"></span>
   <span className="content-pics text-left ">
   <p className="middle ">Vi bryr oss om dig</p> </span> 
   </div>
   <div class="col-12 pic-1  picsall">
   <span className="pics pics4"></span>
   <span className="content-pics text-left ">
   <p className="middle ">Vi älskar det vi gör</p></span> 
   </div>
    
  </div>
</div>





    </div> 
         </FadeInSection> 
        
  
    );
  }

