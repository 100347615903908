export const navItems = [
  {
    id: 1,
    title: "Hem",
    path: "./",
    nName: "nav-item",
    sName: "sidebar-item",
  },
  {
    id: 2,
    title: "Tjänster",
    path: "./services",
    nName: "nav-item",
    sName: "sidebar-item",
  },
  {
    id: 3,
    title: "Om oss",
    path: "./about",
    nName: "nav-item",
    sName: "sidebar-item",
  },
  // {
  //   id: 4,
  //   title: "Blog",
  //   path: "./blog",
  //   nName: "nav-item",
  //   sName: "sidebar-item",
  // },
  {
    id: 5,
    title: "Priser",
    path: "./services",
    nName: "nav-item",
    sName: "sidebar-item",
  },
  {
    id: 6,
    title: "Kontakt",
    path: "./#consultation",
    nName: "nav-item",
    sName: "sidebar-item",
  },
];

// export const serviceDropdown = [
//   {
//     id: 1,
//     title: "Yoga",
//     path: "./services",
//     cName: "submenu-item",
//   },
//   {
//     id: 2,
//     title: "Massage",
//     path: "./services",
//     cName: "submenu-item",
//   },
//   {
//     id: 3,
//     title: "Youga Tour",
//     path: "./services",
//     cName: "submenu-item",
//   },
//   {
//     id: 4,
//     title: "Trenings",
//     path: "./services",
//     cName: "submenu-item",
//   },
// ];
