import React from 'react';
import '../../App.css';
import AboutP from "../AboutP";
import Footer from "../Footer";
import Herosection from "../Herosection";

export default function About() {
  return (
  <div>
  <div className='about' id="about"></div>
  <Herosection/>
  <AboutP/>
  <Footer/>
  </div> 
  );
}


// export default About;