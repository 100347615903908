import React from "react";
import { Link } from "react-router-dom";
import "./Herosection.css";
// import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";

function HeroSection() {
  return (
    <div className="hero-container" id="hero">
      <div className="clean-small">
        <div>
          <img
            className="clean"
            src="../images/clean4.jpg"
            path="/about"
            alt=""
          />

          <div className="allTitle text-left">
            <div className="hero-title1  ">
              {" "}
              vi håller den fräsch &<br /> och strålande ren
            </div>
            <div className="hero-title2 ">
              {" "}
              Vi ger den bästa servicen
              <br /> till de bästa priserna{" "}
            </div>
          </div>
          <Link to="/#consultation" className="link_hero ">
            <p className="align-middle">KONTAKTA OSS</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
