import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import './AboutP.css';
import 'bootstrap/dist/css/bootstrap.css';

// import FadeInSection from './FadeInSection';

function AboutP() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <div id="" className="About-container flex ">
     <div className="decoraction-title"> <p className="title who text-center"> OM OSS</p></div>

    <div class="row pictures justify-content-center ">
                <div class=" col-sm-12 col-md-6 col-lg-6 col-xl-6 colum-about colum-img1 align-top"></div> 
               <div class=" col-sm-12 col-md-6 col-lg-6 colum-about  ">
               <p className="informationServices-about ">
               Vi är ett företag med 10 års erfarenhet inom rengöring av olika slag. Vi erbjuder 
               hög kvalité av våra tjänster, och vi har kunskap för att underlätta våra kunders vardag. 
               Vårt företag växer med varje dag, och var och en av våra nya kunder får högsta kvalitet resultat. 
              Vi gör det vi älskar och älskar det vi gör.</p>  
           
               </div> 

        
    </div>
    
    
    </div>
         
         
        );
      }
     
       
 
  
export default AboutP;
