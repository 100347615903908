import React, { Component } from "react";
import Slider from "react-slick";
import "./Review.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import FadeInSection from "./FadeInSection";


export default class Review extends Component {
  render() {
    const settings = {
      dots: false,
      fade: true,
      infinite: true,  
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };
    return (
      <FadeInSection>
      <div className="slider-container ">
  
      <div className="image-slider ">
      <h1 className="title">RECENSION</h1>
         
        <Slider {...settings}>
          <div>
          <img className=" img-y" src="/images/1.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="/images/2.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="/images/3.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/4.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/5.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/6.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/7.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/8.jpg"  path="/about" alt=""/>
          </div>
        
        </Slider>
      </div>
      </div>
      </FadeInSection> 
    );
  }
}