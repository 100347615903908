import React from "react";
// import { Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ServiceSection.css';
 import { Link } from "react-router-dom";
import './Button.css';
// import Button from 'react-bootstrap/Button';
 import FadeInSection from "./FadeInSection";

 

const ServiceSection = () => {
  return ( 
     <FadeInSection>
    <div className="cont-services icon-box pb-5" >

     <div className="carts-circle">
  <h3 className=" title text-center">TJÄNSTER</h3>

  <div className="container row mx-auto">
    <div className="cart-container  px-0  col-6 col-sm-6 col-md-3 col-lg-3">
      <Link className="cart" to="/services#Veckostadning" >
        <div className=" image-one img-all"></div>
        <div className="title-services text-center">Veckostädning </div>
      </Link>
    </div>

    <div className="cart-container  px-0 col-6 col-sm-6 col-md-3 col-lg-3">
      <Link className="cart" to="/services#Stadning" >
        <div className="image-two img-all"> </div>
        <div className="title-services text-center">Städning utanför Stockholm</div>
      </Link>
    </div>

    <div className="cart-container px-0  col-6 col-sm-6  col-md-3 col-lg-3">
      <Link className="cart" to="/services#Flyttstadning" >
        <div className="image-three img-all" > </div>
        <div className="title-services text-center">Flyttstädning</div>
      </Link>
    </div>

    <div className="cart-container  px-0 col-6  col-sm-6 col-md-3 col-lg-3">
      <Link className="cart" to="/services#Storstadning" >
        <div className="image-four img-all"> </div>
        <div className="title-services text-center"> Storstädning</div>
      </Link>
    </div>




    <div className="cart-container  px-0 col-sm-6 col-6 col-md-3 col-lg-3">
      <Link className="cart" to="/services#Kontorsstad" >
        <div className=" image-five img-all"></div>
        <div className="title-services text-center"> Kontorsstäd </div>
      </Link>
    </div>

    <div className="cart-container  px-0 col-sm-6 col-6 col-md-3 col-lg-3">
      <Link className="cart" to="/services#Fonsterputs" >
        <div className="image-six  img-all"> </div>
        <div className="title-services text-center"> Fönsterputs </div>
      </Link>
    </div>

    <div className="cart-container  px-0 col-sm-6 col-6 col-md-3 col-lg-3">
      <Link className="cart" to="/services#Barnpassning" > 
        <div className="image-seven img-all" > </div>
        <div className="title-services text-center">Barnpassning</div>
      </Link>
    </div>

    <div className="cart-container  px-0 col-sm-6 col-6 col-md-3 col-lg-3">
      
      <Link className="cart" to="/services#Entrestadning" >
        <div className="image-eight img-all"></div>
        <div className="title-services text-center"> Entréstädning</div>
      </Link>
    </div>
    
    
    
  </div>
</div> 


  
       </div>

      </FadeInSection>   
  );
};

export default ServiceSection;
 