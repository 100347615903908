 import React from "react";
 import '../../App.css';
 import HeroSection from '../Herosection.js';
 import ServiceSection from '../ServiceSection.js';
 import Footer from "../Footer";
 import HomeImages from "../HomeImages";
 import WhyUs from "../WhyUs";
 import Contact from "../Contact";
 import Review from "../Review";





function Home() {
  return (
    <>
   
    <HeroSection/>
    <ServiceSection/>
    <HomeImages/>
     <WhyUs/>
     <Contact/> 
     <Review/>   
    <Footer/>
    </>
  );
}

export default Home;