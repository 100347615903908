import React from 'react';
import '../../App.css';
// import CardServices from "../CardServices";
import Herosection from "../Herosection";
import CardServices from "../CardServices";
import Footer from "../Footer";

 function Services() {
  return (
  <div >
  <div className='services' id="services">

  </div>
   <Herosection/> 
  {/* <CardServices/> */}
  <CardServices/>
 <Footer/>
  
  
  </div> 
  );
}

export default Services;