import React from "react";
import "./Footer.css";
// import { Button } from "./Button";
// import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';




function Footer() {
  return (
 
    <div className="footer-container" id="contact">

<div class="container">
  <div class="row w-100">
    <div class="col footer-col">
   
      <div class="footer-link-items">
            <h2 className="followUs">Följ oss</h2>
          
            <a href="/" target="_blank" rel="noreferrer" >Facebook</a> <br/>
            {/* <a href="/" target="_blank" rel="noreferrer" >LinkedIn</a> <br/>
            <a href="/" target="_blank" rel="noreferrer" >Instagram</a> <br/> */}
           
           
            
            <img className="img_logo " src="../images/cl2.jpg" path="/about" alt=""/>
           <div className="address">
           
           </div>
              
    </div> 
    </div>
    <div class="col footer-col">
    <div>Adress huvudkontor:<br/>
Runby Tog 9, 19446 Upplands Väsby</div> <br/>

    <div><i class="fas fa-mobile-alt"> +46 793 353 821  </i> </div>
    <div><i class="far fa-envelope"> info@svrent.se</i>
</div> 
   
     
     
       
      {/* <div className="social-icons justify-content-between">
      <a href="/" class="social-icon-link facebook" target="_blank" rel="noreferrer" ><i class="fab fa-facebook-square"></i></a>
             <a href="/" class="social-icon-link linkedin" target="_blank" rel="noreferrer" ><i class="fab fa-linkedin" /></a>
            <a href="/"class="social-icon-link linkedin" target="_blank" rel="noreferrer" ><i class="fab fa-instagram"></i></a> 
      </div> */}
     
    </div>
  </div>
</div>
  


  <div class="website-rights mx-auto "> © 2021 <span>
  <a href="https://www.nextek.se/" class="nextek" target="_blank" rel="noreferrer" >Digital Magic by NexTek</a></span> </div>

     
    </div>
  
  );
};

export default Footer;
